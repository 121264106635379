<template>
  <v-dialog
    v-model="fileUploadVisible"
    scrollable
    persistent
    width="800"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        :disabled="btnDisabledFileUpload"
        v-bind="attrs"
        v-on="on"
        class="btn mt-10"
        >{{ $t('File Upload') }}</v-btn
      >
    </template>
    <v-card class="popup add_store_popup">
      <v-card-title>
      <h3 class="page-title-bar">
        <i class="ico ico-product"></i>{{ $t('File Upload') }}
      </h3>
      </v-card-title>
      <v-card-text >
        <div style="width:600px">
      <v-row>
        <v-col>
          <v-file-input
            outlined
            dense
            hide-details
            prepend-icon
            truncate-length="130"
            solo
            flat
            accept=".tar.gz"
            :placeholder="`${$t('Select file')}`"
            class="form-file"
            v-model="file"
            @keydown="clearTabindex"
          >
            <template v-slot:append>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col>
          <p class="Gateway-txt">{{ $t('Selected Files') }}</p>
          <ul class="selectedFile-list mt-2">

            <li v-for="file in filesToUpload" :key="file.name">
              <p>{{ file.name }}</p>
              <v-btn @click="removeFile(file.name)" text icon
                ><img width="24" height="24" src="@/assets/img/ico-delete2.png"
              /></v-btn>
            </li>
          </ul>
        </v-col>
      </v-row> -->
      </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn text icon @click="uploadFiles()"  class="btn">{{
          $t('Upload')
        }}</v-btn>
        <v-btn text icon @click="cancelFileUpload()" class="btn">{{
          $t('Cancel')
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <msg-box :msgBox="msgBox" @closeMsgBox="closeMsgBox" />
  </v-dialog>
</template>

<script>
// Utils
import commons from '@/plugins/commons'
// import codes from '@/plugins/codes'
// Mixins
import MsgBoxParent from '@/mixins/MsgBoxParent'
import FileUploader from '@/mixins/FileUploader'
import EventBus from '@/plugins/eventBus'

export default {
  name: 'GatewayFirmwareUploader',
  mixins: [FileUploader, MsgBoxParent],
  data () {
    return {
      testTrue: true,
      testFalse: false,
      requests: {
        uploadFiles: {
          method: 'POST',
          url: '/api/common/otamanager/api/gw/otapackage'
        }
      }
    }
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    async buildFileUploadBody (fileList) {
      const fileDataList = []
      // for (const f of fileList) {
      //   const fileData = {
      //     data: await commons.getBase64(f),
      //     fileName: file.name
      //   }
      //   fileDataList.push(fileData)
      // }
      const fileData = {
        data: await commons.getBase64(fileList),
        fileName: fileList.name
      }
      fileDataList.push(fileData)
      return fileDataList
    },
    async uploadFiles () {
      this.fileIsEmpty()
      if (this.fileIsEmpty(this.file)) {
        this.openMsgBox(this.$t('Please enter the Data.'))
        return
      }
      const body = { fileList: await this.buildFileUploadBody(this.file) }
      console.log(body.fileList[0].fileName)
      var fname = body.fileList[0].fileName
      var re = /(\.tar.gz)$/i
      if (!re.exec(fname)) {
        EventBus.$emit(
          'messageAlert',
          this.$t('This extension is not supported.')
        )
        return
      }
      const params = {}
      const config = { params }
      const msg = 'Waiting for Uploading'
      EventBus.$emit('toggleLoadingModal', msg)
      this.$utils
        .callAxiosWithBody(
          this.requests.uploadFiles.method,
          this.requests.uploadFiles.url,
          body,
          config
        )
        .then(res => {
          EventBus.$emit('toggleLoadingModal', '')
          this.openMsgBox(this.$t(res.data.responseMessage))
          this.$emit('initGatewayFirmware')
          this.cancelFileUpload()
        })
        .catch(error => {
          EventBus.$emit('toggleLoadingModal', '')
          if (error.response.data.responseCode > 300) {
            this.openMsgBox(this.$t(error.response.data.responseMessage))
          }
          console.debug(
            `Failed to upload Gateway firmware package. error: ${error}`
          )
        })
    },
    fileIsEmpty (file) {
      if (file === null || file === undefined || file === '') {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
